import React, { useState, useEffect } from 'react';
import { imageOnLoad } from '../../../../js/CDNUtility';
import configData from "../../../../appsettings.json";

export const TourBoxStatic = ({ tour, codiceProdotto, index, modalOpenTourId }) => {
    let imageOnLeft = index % 2 === 0;

    function onModalOpenTourId(productId) {
        modalOpenTourId(productId);
    }

    return (
        <>
            <div id={`tourDetail_${tour.tourId}`} className="w-100">
                <div className="card border-gray-600 my-4 animate-up-5 shadow">
                    <div className="row no-gutters">
                        {/*thumb left*/}
                        {imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + (tour.thumbUrl ? encodeURI(tour.thumbUrl) : imageOnLoad(configData.Settings.Products.Tour.IdTipoProdotto)) + "), url(" + imageOnLoad(configData.Settings.Products.Tour.IdTipoProdotto) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                            </div>
                        </div>}

                        {/*body*/}
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="myBook-card-body">
                                <div>
                                    <div className="h5">
                                        <var>{tour.name}</var>
                                        <span className="float-end">
                                            <label className="btn-neutral text-decoration-underline px-2" onClick={e => onModalOpenTourId(codiceProdotto)}>Maggiori Dettagli</label>
                                        </span>
                                    </div>
                                    <div className="w-100" style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                                        <span className="text-justify">
                                            {tour.description
                                                ? (<><p dangerouslySetInnerHTML={{ __html: tour.description }}></p></>)
                                                : (<></>)
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*thumb right*/}
                        {!imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + (tour.thumbUrl ? encodeURI(tour.thumbUrl) : imageOnLoad(configData.Settings.Products.Tour.IdTipoProdotto)) + "), url(" + imageOnLoad(configData.Settings.Products.Tour.IdTipoProdotto) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}
